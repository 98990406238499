@import url('https://fonts.googleapis.com/css2?family=Metrophobic&family=Rubik+Bubbles&display=swap');

p{
    
    font-family: sans-serif;
    font-weight: 150;
    
    text-align: justify;
    
}



.bgfadetopmoving{
    background-image: linear-gradient(rgb(255, 255, 255),rgba(255, 255, 255, 0));
    z-index: -1;
    position: fixed;
    width: 100%; /* Full width (cover the whole page) */
    height: 30%; /* Full height (cover the whole page) */
    top: -50px;
    left: 0;
    right: 0;
    bottom: 0;
}

.bgfadebottom{
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgb(255, 255, 255) );
    z-index: -1;
    position: absolute;
    top: 50px;
    width: 100%; /* Full width (cover the whole page) */
    height: 70%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.bgfadetop{
    background-image: linear-gradient( rgb(255, 255, 255),rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) );
    z-index: -1;
    position: absolute;
    
    width: 100%; /* Full width (cover the whole page) */
    height: 70%; /* Full height (cover the whole page) */
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
}

h1{
    
    font-family: sans-serif;
    font-weight: 150;
    margin-bottom: 30px;
    letter-spacing: 7px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    border-color: rgba(0, 0, 0, 0.105);
    height: 40px;
    
}

h2{
    font-family: sans-serif;
    text-align: center;
    font-weight:  bolder;
}




.wrapper{
        display: flex;
        flex-direction: column;
        background-color: rgba(172, 213, 243, 0.164);
        border-bottom-style:solid;
        border-width: 3px;
        border-color: #24568b54;
        border-radius: 7px;
        box-shadow: 0 10px 20px 0 rgba(126, 121, 121, 0.127), 0 6px 20px 0 rgba(160, 151, 151, 0.082);
}

img{
           
            height :max-content;
            align-self: center;
            
}
.service{
            margin-top: 250px;
            width: 90%;
            display: grid;
            font-family: 'Metrophobic', sans-serif;
            letter-spacing: 2px;
            justify-content: center;
            
            
}

.section{
            align-self: center;
            padding: 10px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 70%;
            margin-bottom: 70px;
            margin-top: 30px;
            justify-content: center;
            
          
           
        
            
}

.bg {
            background-image: url("./images/cover.jpg");
            background-repeat:no-repeat;
            background-size: cover;
            z-index: -2;
            position: absolute;
            width: 100%; /* Full width (cover the whole page) */
            height: 70%; /* Full height (cover the whole page) */
            top: 0px;
            left: 0;
            right: 0;
            bottom: 0px;
}
.list{
    list-style-type: none;
}

.listContainer{
    margin-top: 20px;
}

.listContainer>ul{
    padding: 0;
}

.listContainer>ul>li{
    margin-top: 10px;
}

.description{
   text-align: center;
}

.itemImage{
    border-radius: 10px;
    height: 200px;
}

iframe{
    width: 100%;
    height: 600px;
    border: 0px none;
    margin-bottom: 50px;
}

.covidTitle{
    margin-top: 70px;
}

.evidTitle{
    min-width: 500px;
}