@import url('https://fonts.googleapis.com/css2?family=Metrophobic&family=Rubik+Bubbles&display=swap');


.main {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.greet{
  text-align: center;
  align-items: center;
  
  position:fixed;
  bottom:0px;
  right: 0px;
  
  background-color: white;
  font-family: sans-serif;
  border-top-left-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}