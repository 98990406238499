@import url('https://fonts.googleapis.com/css2?family=Metrophobic&family=Rubik+Bubbles&display=swap');

.card{
    background-color: rgb(223, 239, 251);
    border-bottom-style:solid;
    border-width: 3px;
    border-color: #24568b54;
    border-radius: 5px;
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    /* height: 600px; */
    box-shadow: 0 10px 20px 0 rgba(126, 121, 121, 0.127), 0 6px 20px 0 rgba(160, 151, 151, 0.082);
}

.main{
    font-size: large;
    font-family: 'Metrophobic', sans-serif;

}

