@import url('https://fonts.googleapis.com/css2?family=Metrophobic&family=Rubik+Bubbles&display=swap');
.form{
    background-color: rgba(172, 213, 243, 0.164);
    border-bottom-style:solid;
    border-width: 3px;
    border-color: #24568b54;
    border-radius: 5px;
    width: 40%;
    margin: 20px auto;
    padding: 20px;
    /* height: 600px; */
    box-shadow: 0 10px 20px 0 rgba(126, 121, 121, 0.127), 0 6px 20px 0 rgba(160, 151, 151, 0.082);
}
.formBody{
    text-align: left;
    padding: 20px 10px;
    font-family: 'Metrophobic', sans-serif;
}
.formBody > *{
    padding: 10px;
}
input[type=text] {
    margin-left: 5px;
    padding:10px;
    border:0;
    border-radius: 5px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  }

  input[type=password] {
    margin-left: 5px;
    padding:10px;
    border:0;
    border-radius: 5px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  }
  
  
input[type=email] {
    margin-left: 5px;
    padding:10px;
    border:0;
    border-radius: 5px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  }
.label{
    width: 40%;
}
textarea{
    margin-left: 5px;
    padding:10px;
    border:0;
    border-radius: 5px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    vertical-align: top;
    font-family: 'Metrophobic', sans-serif;
}
.form_input{
    width: 60%;
    
}

.btn{
    font-family: 'Metrophobic', sans-serif;
    height:auto;
    width: auto;
    font-size: 15px;
    padding: 5px;
    background-color:#24568b54;
    border-style: none;
    border-bottom-style: solid;
    border-radius: 5px;
    color: black;
    text-align: center;
    box-shadow: 0 10px 20px 0 rgba(126, 121, 121, 0.127), 0 6px 20px 0 rgba(160, 151, 151, 0.082);

    padding-left: 20px;
    padding-right: 20px;

    font-weight: 500;

    margin-left: 15px;
}

input[type=number] {
    -moz-appearance: textfield;
    margin-left: 5px;
    padding:10px;
    border:0;
    border-radius: 5px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  }

input::-webkit-inner-spin-button {          /*kod za skrivanje strelica na unosu brojeva*/
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.btn:hover{
color: white;
background-color:#163556ba;
}

section{
    width: 100%;
    display: flex;
    justify-content: center;
}

p{
    font-size: 1.1rem;
}

.service{
    width: 90%;
    display: grid;
    font-family: 'Metrophobic', sans-serif;
    justify-content: center;
    grid-template-rows: 0.2fr 1fr;
}

.wrapper{
    display: flex;
    flex-direction: row;
    background-color: rgba(172, 213, 243, 0.164);
    border-bottom-style:solid;
    border-width: 3px;
    border-color: #24568b54;
    border-radius: 7px;
    text-align: left;
    box-shadow: 0 10px 20px 0 rgba(126, 121, 121, 0.127), 0 6px 20px 0 rgba(160, 151, 151, 0.082);
    
}

img{
    width: 80%;
    height: 50%;
    object-fit: cover;
    
}

 .section{
    padding: 10px;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 0.5fr 0.3fr 2fr;
    
 }
 
 select{
    margin-left: 5px;
    padding:10px;
    border:0;
    border-radius: 5px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    margin-top: 6px;
 }

