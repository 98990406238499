@import url('https://fonts.googleapis.com/css2?family=Metrophobic&family=Rubik+Bubbles&display=swap');

.header{
    background-color: rgb(255, 255, 255);
    box-shadow: 0 10px 20px 0 rgba(126, 121, 121, 0.127), 0 6px 20px 0 rgba(160, 151, 151, 0.082);
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1.5fr 1fr;
    
    
   
}

.logoImage{

    width: 60px;
    height: 60px;
    object-fit: cover;

    position: relative;
    top: 19px;
    

}

.headerContainer{
    margin-bottom: 10px;
}



.linksContainer{
    margin: 0px 10px ;
    margin-right: 50px;
    margin-top: 5px;
    display: grid;
    grid-auto-flow: column;

}

.linkElement{
    margin: 5px;
    padding: 5px;
    background-color: rgb(255, 255, 255);
    text-align: center;
    border-bottom-style: solid;
    border-width: 3px;
    border-color: #24568b54;
    text-decoration: none;
    color: rgb(22, 35, 52);
    font-size:16px;
    padding-top: 10px;
    letter-spacing: 3px;
    font-weight: 350;
    
    
    
}

.logo{
    font-family: 'Metrophobic', sans-serif;
    font-weight: 100;
    font-size:35px;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: 13px;
    display: block;
    
 
 
}

.linkElement:hover{
    background-color:  rgba(172, 213, 243, 0.164);
    margin-bottom: 0%;
    margin-top: 5px;
    font-size: 17px;
    font-weight: 450;
    box-shadow: 0 10px 20px 0 rgba(126, 121, 121, 0.127), 0 6px 20px 0 rgba(160, 151, 151, 0.082);
}

@media only screen and (max-width: 900px){
    .header{
        background-color: rgb(255, 255, 255);
        box-shadow: 0 10px 20px 0 rgba(126, 121, 121, 0.127), 0 6px 20px 0 rgba(160, 151, 151, 0.082);
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: auto;
        
        
       
    }

    .linksContainer{
        margin: 0px 10px ;
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 2px;
        display: grid;
        
        align-self: center;
    
    }
    
}


@media only screen and (max-width: 600px){
    .header{
        background-color: rgb(255, 255, 255);
        box-shadow: 0 10px 20px 0 rgba(126, 121, 121, 0.127), 0 6px 20px 0 rgba(160, 151, 151, 0.082);
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: auto;
        
        
       
    }

    .linksContainer{
        margin: 0px 10px ;
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 2px;
        display: grid;
        grid-template-rows: auto ;
        align-self: center;
        grid-auto-flow: row;
    
    }
    
}