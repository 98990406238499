.container{
    text-align: center;
    position: fixed;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    display: flex;
    align-items: center;

}
.btn{
    font-family: 'Metrophobic', sans-serif;
    height:auto;
    width: auto;
    font-size: 15px;
    padding: 5px;
    background-color:#24568b54;
    border-style: none;
    border-bottom-style: solid;
    border-radius: 5px;
    color: black;
    text-align: center;
    box-shadow: 0 10px 20px 0 rgba(126, 121, 121, 0.127), 0 6px 20px 0 rgba(160, 151, 151, 0.082);

    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    font-weight: 500;

    margin-left: 15px;
}

.btn:hover{
    color: white;
    background-color:#163556ba;
}


    
.content{
    margin: auto;
    width: 50%;
}